<template>
  <DataTable :options="options" />
</template>

<script>
import { RATINGS, SENTIMENTS } from '@tutti/constants';

export default {
  data() {
    return {
      options: {
        action: {
          hideEdit: true,
          onView: this.onView,
        },
        export: true,
        filters: [],
        headers: [
          {
            text: 'Booking number',
            value: 'bookingId',
            sortable: false,
            component: { name: 'dtView', props: { name: 'booking', permission: 'booking' } },
          },
          { text: 'Rating', value: 'rating' },
          { text: 'Review', value: 'review', sortable: false },
          { text: 'Source', value: 'source', width: '100px' },
          {
            text: 'Author',
            value: 'from',
            filter: true,
            sortable: false,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
        ],
      },
    };
  },
  async created() {
    this.options.filters = [
      {
        label: 'Ratings',
        items: RATINGS,
        key: 'ratings',
      },
      {
        label: 'Sentiments',
        items: SENTIMENTS,
        key: 'sentiments',
      },
      {
        label: 'TBD',
        items: [],
        itemText: 'name',
        itemValue: '_id',
        key: 'tbd1',
        disabled: true,
      },
      {
        label: 'TBD',
        items: [],
        key: 'tbd2',
        disabled: true,
      },
    ];
  },
  methods: {
    onView(item) {
      this.$view('booking', item.bookingId._id);
    },
  },
};
</script>
